import React,{useState,useEffect} from 'react'
import './DetailPage.css'
import {Button} from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {useHistory} from 'react-router-dom'
import {Dialog,DialogActions,TextField,DialogContent,DialogContentText,DialogTitle,TableFooter} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {DropzoneArea} from 'material-ui-dropzone'


import {Link} from "react-router-dom"
import {connect} from "react-redux"
import { bindActionCreators } from "redux";


import * as Actions from "./../../redux/AppStore/Actions";
import { version } from 'react';



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const DetailPage = ({getAppDetail,appdetail,updateApkData,updateVersion,updateapksuccess,isLoading,handleResetAction,match}) => {

  const history = useHistory();

  const [detail,setDetail] = useState([])

    const [Values, setValues] = useState({
        title:"",
        apkpath:"",
        loading:false,
        update:"",
        appid:"",
        versions:[],
        published_version:"",
        app_key:"",
        description:"",
        package_name:"",
        snackopen:false,
        formdata:new FormData(),
        error:false,
        errortext:""
    })
const [open, setOpen] = React.useState(false);


const handleClickOpen = (id) => {
    // setValues({...Values,appid:id})
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlesnackClose = () => {

    setValues({...Values,snackopen:false})

  }

const {errortext,error,title,snackopen,appid,update,loading,packageurl,versions,formdata,published_version,package_name,description,app_key} = Values;

   
            
            const releaseHandler = (id,version) => {
              updateVersion(id,version)
            }

            const preload = () => {
           getAppDetail(match.params.appid);


            }

            useEffect(()=>{


                setValues({...Values,
                  versions:appdetail.versions,
                  update:appdetail.updatedAt,
                  published_version:appdetail.published_version,
                  package_name:appdetail.package_name,
                  title:appdetail.name,
                  description:appdetail.description,
                  app_key:appdetail.app_key,
                  formdata:new FormData()
                });

                setDetail([appdetail])
                
              },[appdetail])
  
  

            useEffect(()=>{

              preload();
              
            },[])

            useEffect(()=>{

              preload();

             
              if(updateapksuccess)
              {
              setValues({...Values,snackopen:true})
              }
              setTimeout(()=>{
              handleResetAction()
              setOpen(false);
              },4000)

            },[updateapksuccess])

            const updateApp = () => {
                return (
                  <div>
                    <Dialog 
                    open={open} 
                    onClose={handleClose}
                     aria-labelledby="form-dialog-title">
                      <DialogTitle id="form-dialog-title">New Versions</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          New version should be greater than the latest version
                        </DialogContentText>
              
                       <form action="">

                       <DropzoneArea
                                     acceptedFiles={['application/*']}
                                     showAlerts={false}
                                     filesLimit={1}
                                     maxFileSize={5000000000}
                                    name="file"
                                    multiple={false}
                                    onChange={(files)=>formdata.set("file",files[0])} />

              
                        <TextField
                        onChange={(e)=>
                          {
                            console.log(versions)
                            if(versions[versions.length-1] > e.target.value )
                            {
                              setValues({...Values,error:true,errortext:"Please enter greater version number"})
                            }
                            else{
                              setValues({...Values,error:false,errortext:""})

                            }

                            formdata.set("version",e.target.value)}
                            
                          }
                          type="number"
                          // autoFocus
                          margin="dense"
                          id="name"
                          label="Version no"
                          type="text"
                          name="releasename"
                          fullWidth
                          error={error}
                          helperText={errortext}
                        />
                        </form>
                      </DialogContent>
                      <DialogActions>
                      { isLoading ? <CircularProgress /> : <>
                       <Button 
                        onClick={()=>handleSubmit()}
                         color="primary">
                          YES
                        </Button>
                        <Button 
                        onClick={handleClose}
                         color="primary">
                          NO
                        </Button>
                        </>
                        }
                      </DialogActions>
                    </Dialog>
                  </div>
                );
              }
              
              const handleSubmit =()=>{

                

                updateApkData(formdata,match.params.appid)
            
              }
            
              


    return (
      <div className="update-application">
        <Snackbar open={snackopen} autoHideDuration={6000} onClose={handlesnackClose}>
        <Alert onClose={handlesnackClose} severity="success">
         Updated Success
        </Alert>
        </Snackbar> 
          <Typography variant="h4" style={{padding:"15px"}}>
            {title}
          </Typography>

          <Link to="/appstoredashboard" style={{position:"absolute",right:"20px",color:"white"}}>
          <KeyboardBackspaceIcon />
          </Link>

          <div className="update-area">
            <div className="left">
              <div className="apk">
                {/* <Typography variant="h6" >
                    APK
                </Typography>
           */}

              </div>

              <div className="current-apk">
                {/* <Typography variant="h6" >
                   CURRENT APK
                </Typography> */}

                <Typography variant="subtitle1" >
                 { update == undefined ? "" : <span><strong> published on: </strong>{update.substr(0,10)}</span>}
                </Typography>
                  
              </div>  
            </div>
            
            <div className="right">
            {/* <Button variant="contained" onClick={()=>deleteHandler(appid)} >
               Delete Project
            </Button> */}
            </div>

          </div>

            <hr />
          <div className="package-area">

            <div className="package-name">
              <Typography variant="subtitle1" color="initial">
                Package name
              </Typography>

              <Typography variant="h6" color="initial">
              {package_name}
              </Typography>
            </div>

            <div className="version">
            <Typography variant="subtitle1" color="initial">
               Latest Version
              </Typography>

              <Typography variant="h6" color="initial">
                {published_version}
              </Typography>

            </div>

            <div className="appkey">
            <Typography variant="subtitle1" color="initial">
               App Key
              </Typography>

              <Typography variant="h6" color="initial">
                {app_key}
              </Typography>

            </div>

            <div className="description">
            <Typography variant="subtitle1" color="initial">
               Description
              </Typography>

              <Typography variant="h6" color="initial">
                {description}
              </Typography>

            </div>

          </div>

          <hr />

          <div className="previous-apk">
          <Typography variant="h6" >
                   PREVIOUS APK
            </Typography>

            <TableContainer style={{width:"50%"}} component={Paper}>
            <Table 
            // className={classes.table}
             aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>VERSION</TableCell>
                  <TableCell >Release</TableCell>
                </TableRow>
              </TableHead>
             
                {detail== undefined?<h3>Could not found App in server </h3> :detail.map((row,index) => (
                     <TableBody key={index}>
                       {
                         versions == undefined ? <TableRow><TableCell><CircularProgress /></TableCell></TableRow>  :versions.map((v,index)=>(
                             <TableRow key={index}>
                        <TableCell>
                        {row.name}
                        </TableCell>
                        <TableCell >
                           {v}
                        </TableCell>
                        <TableCell >
                        <Button variant="contained" onClick={()=>releaseHandler(row._id,v)} >
                            Release Apk
                        </Button>
                            </TableCell> 
                         </TableRow>
                           ))
                       }
              </TableBody>

                )
                     )
                    }
            </Table>
          </TableContainer>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        {/* <DialogTitle id="form-dialog-title">Create App</DialogTitle> */}
        <DialogContent>
          
        {updateApp()}
        </DialogContent>
       
      </Dialog>
        <Fab onClick={handleClickOpen} style={{position:"absolute",bottom: "50px",right:"60px"}} color="primary" aria-label="add">
          <AddIcon />
        </Fab>   
            
          </div>
        </div>

    )
}

const mapStateToProps = (state) => ({
    ...state.AppStore,
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
      ...bindActionCreators({ ...Actions }, dispatch),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(DetailPage);
