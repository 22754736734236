export const APPDATA_SUCCESS = 'AppStore/APPDATA_SUCCESS';
export const APPIMAGE_SUCCESS = 'AppStore/APPIMAGE_SUCCESS';
export const APKFILE_SUCCESS = 'AppStore/APKFILE_SUCCESS';
export const APPDATA_FAILURE = 'AppStore/APPDATA_FAILURE';
export const APPIMAGE_FAILURE = 'AppStore/APPIMAGE_FAILURE';
export const APKFILE_FAILURE = 'AppStore/APKFILE_FAILURE';
export const GETDATA_SUCCESS = 'AppStore/GETDATA_SUCCESS';
export const GETDATA_FAILURE = 'AppStore/GETDATA_FAILURE';
export const UPDATEAPK_FAILURE = 'AppStore/UPDATEAPK_FAILURE';
export const UPDATEAPK_SUCCESS = 'AppStore/UPDATEAPK_SUCCESS';
export const GETAPPDETAIL_FAILURE = 'AppStore/GETAPPDETAIL_FAILURE';
export const GETAPPDETAIL_SUCCESS = 'AppStore/GETAPPDETAIL_SUCCESS';
export const UPDATEVERSION_FAILURE = 'AppStore/UPDATEVERSION_FAILURE';
export const UPDATEVERSION_SUCCESS = 'AppStore/UPDATEVERSION_SUCCESS';
export const ISLOADING = 'AppStore/ISLOADING';
export const RESET = 'AppStore/RESET';


